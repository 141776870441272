import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { lockClosed } from "@heroicons/react";
import Axios from "axios";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  const login = () => {
    Axios.post("http://localhost:3001/api/v1/login", {
      email: email,
      password: password,
    }).then((response) => {
      console.log(response);
    });
  };
  return (
    <>
      <div className="h-screen grid grid-cols-1 lg:grid-cols-12">
        <div className="lg:col-span-9 h-full relative">
          <img
            className="h-full w-full object-cover absolute"
            src="https://images.pexels.com/photos/2335126/pexels-photo-2335126.jpeg"
            alt=""
          />
          <div className="relative bg-gradient-to-t from-white to-gray-400/0 h-full w-full">
            <div className="max-w-7xl mx-auto px-8 h-full flex items-center">
              <h2 className="">Mehr sicherheit für ihr tägliches Business.</h2>
            </div>
          </div>
        </div>

        <div className="order-first lg:order-last lg:col-span-3 bg-white px-10 py-8 rounded-lg shadow-lg h-full w-full flex items-center">
          <div>
            <form className="space-y-6" action="">
              <div className="">
                <h2 className="">Login bei ITC</h2>
                <p className="text-gray-500 mt-1 flex gap-1 items-center text-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-3 h-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Ihre Daten werden verschlüsselt versendet.
                </p>
              </div>
              <TextField
                fullWidth
                id="outlined-basic"
                label="E-Mail"
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Passwort"
                variant="outlined"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Button onClick={login} size="large" variant="contained">
                Login
              </Button>
            </form>
            <div className="my-5">
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">
                    Oder
                  </span>
                </div>
              </div>
            </div>
            <Button variant="outlined">Registrieren</Button>
            <p className="text-sm text-gray-500 mt-4">
              Melden Sie sich mit Ihren Daten an. Sollten Sie noch keinen Zugang
              haben, können Sie sich einfach und kostenlos registrieren.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
