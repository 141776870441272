import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import Axios from "axios";

function Register() {
  const [emailReg, setEmailReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");

  const register = () => {
    Axios.post("http://localhost:3001/api/v1/register", {
      email: emailReg,
      password: passwordReg,
    }).then((response) => {
      console.log(response);
    });
  };

  return (
    <div>
      Register
      <TextField
        onChange={(e) => {
          setEmailReg(e.target.value);
        }}
        fullWidth
        id="outlined-basic"
        label="E-Mail"
        variant="outlined"
      />
      <TextField
        onChange={(e) => {
          setPasswordReg(e.target.value);
        }}
        fullWidth
        id="outlined-basic"
        label="Password"
        variant="outlined"
      />
      <Button onClick={register} size="large" variant="contained">
        Register
      </Button>
    </div>
  );
}

export default Register;
